// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiLocal: "assets/data/",
  tipoIdentificacion:"CC",
  // Identificacion:"918015411",
  // Password:"Pruebas123",
  Identificacion:"1055917862",
  Password:"CC1055917862",
  tokenLogin:"https://paiwebapi.paiweb.gov.co/api/login",
  cambioEstrategia:"https://paiwebws.paiweb.gov.co:8082/api/interop/RegSolicitudCambioEstrategia"
  // tokenLogin:"http://zafiroapi.sispropreprod.gov.co/api/Login",
  // cambioEstrategia:"https://paiwscc.sispropreprod.gov.co:8082/api/interop/RegSolicitudCambioEstrategia"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
