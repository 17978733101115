import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Service } from '../interfaces/service';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaiwebService {
  // headers = new Headers;
  constructor(
    private http: HttpClient
  ) { }

  private getQuery(query: string, data:any, headers:any) {
    let url = query;
    return this.http.post(url,data,{ headers: headers });
  }

  public getToken(){
    let data = {
      tipoIdentificacion:`${ environment.tipoIdentificacion }`,
      Identificacion:`${ environment.Identificacion }`,
      Password:`${ environment.Password }`
    }
    return this.getQuery(`${ environment.tokenLogin }`, data, null)
      .pipe(map((response: Service[]) => {
        return response;
      }));
  }

  public getCambio(token:string, data:Service){
    let headers = { 'Authorization': `${ token }`, 
    'Access-Control-Allow-Origin': '*', 
    'Content-Type': 'application/json'};
    // this.headers.append('Access-Control-Allow-Origin', '*');
    console.log(headers);
    return this.getQuery(`${ environment.cambioEstrategia }`, data, headers)
      .pipe(map((response: Service[]) => {
        return response;
      }));

  }
}
