import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Service } from '../interfaces/service';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

   services: Service[] = [];

  constructor(
    private http: HttpClient
  ) {
    this.getServices();
  }

  private getQuery(query: string) {
    let url = `${ environment.apiLocal }${ query }`;
    return this.http.get(url);
  }

  public getServices(){
    return this.getQuery('data.json')
      .pipe(map((response: Service[]) => {
        return response;
      }));
  }
}
