import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UtilityService} from '../../services/utility.service';
import { first } from 'rxjs/operators';
import { Service } from 'src/app/interfaces/service';
import { PaiwebService } from 'src/app/services/paiweb.service';

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['./datos.component.css']
})
export class DatosComponent implements OnInit {
  public datos: Service[];
  private token: string;
  constructor(
    private router: Router,
    private utilityService: UtilityService,
    private paiwebService: PaiwebService
  ) { }

  ngOnInit() {
    this.getDatos();
  }

  getDatos() {
    this.utilityService.getServices()
      .pipe(first())
      .subscribe((response: Service[]) => {
        this.datos = response;
        console.log(response);
      });
  }

  public getToken(){
    
      this.paiwebService.getToken()
      .pipe(first())
      .subscribe((response:any) => {
        this.token= `Bearer ${response.token}`;
        console.log(this.token);
        let cambio:Service = this.datos[0];
        console.log(cambio);
        this.paiwebService.getCambio(this.token, cambio)
          .pipe(first())
          .subscribe((response:any) => {
            console.log(response);
        })
        // console.log(this.token);
      })
   
  }

public getCambio(){
 
  let cambio:Service = this.datos[0];
  this.paiwebService.getCambio(this.token, cambio)
      .pipe(first())
      .subscribe((response:any) => {
        console.log(response);
      })

}

}
